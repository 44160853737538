import {isRouteErrorResponse} from '@remix-run/react';

import {notify} from '~/foundation/AppSetupContext/context';

interface ReportableConfig {
  unknown404?: boolean;
}

/**
 * Will log to bugsnag for unknown errors.
 * For Promises in particular, we only want to know if they fail.
 */
export function logUnknownError(
  error: unknown,
  _reportableConfig?: ReportableConfig,
) {
  const logError = (_unknownData: unknown) => {
    // console.error('logUnknownError', unknownData);
    // const usableError = convertUnknownToError(unknownData, reportableConfig);
    // if (usableError) {
    //   notify(usableError);
    // }
  };

  // lets ignore all Promises, unless they reject, then log what they reject with
  if (error instanceof Promise) {
    error.catch((promiseError) => {
      logError(promiseError);
    });
  } else {
    logError(error);
  }
}

export function convertUnknownToError(
  error: unknown,
  reportableConfig?: ReportableConfig,
) {
  if (!error) {
    return;
  }

  if (!isReportableError(error, reportableConfig)) {
    return;
  }

  if (
    typeof error === 'object' &&
    'error' in error &&
    error.error instanceof Error
  ) {
    return error.error;
  }

  if (isRouteErrorResponse(error)) {
    const {data, statusText, status} = error;
    return new Error(data.message || `${status} error: ${statusText}`);
  }

  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  if (error instanceof Response) {
    return new Error(
      `Response Error: ${error.status} ${error.statusText} (${error.ok ? 'Okay' : 'Not Okay'})`,
    );
  }

  const unknownErrorString = error?.constructor?.name
    ? `Unknown error: ${typeof error} (${error.constructor.name})`
    : `Unknown error: ${typeof error}`;

  return new Error(unknownErrorString);
}

function isReportableError(
  error: unknown,
  config: ReportableConfig = {unknown404: true},
) {
  if (!error) {
    return false;
  }

  const is404 =
    isRouteErrorResponse(error) &&
    error.status === 404 &&
    typeof error.data === 'string';

  // if this is a 404 error and we don't want to report unknown 404s, we can skip
  if (is404 && !config.unknown404) {
    // First - make sure that Remix hasn't changed their response for 404s
    const regexData = /No route matches URL "(.*?)"/.exec(error.data);
    if (!regexData) {
      notify(
        new Error(
          `Remix has changed their response for 404s, we need to change our regex: ${error.data}`,
        ),
      );

      return true;
    }

    // Next - see if its a URL that looks like it might come from our app
    const [_fullString, url] = regexData;
    if (
      !/filter|search|boost|reco|settings|synonym|graphql|openai|ping|webhook|auth/.test(
        url,
      ) ||
      /\.(xls|json|zip|tar|php)$/.test(url)
    ) {
      return false;
    }
  }

  return true;
}
